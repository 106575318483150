<template>
  <chi-tiet-ho-so />
</template>
<script>
import ChiTietHoSo from '@/modules/xu-ly-ho-so/components/pages/ban-giao-ho-so/ChiTietHoSo.vue'

export default {
  components: {
    ChiTietHoSo,
  },
}
</script>
