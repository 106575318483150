<template>
  <div class="tab-common">
    <input
      id="tab1"
      v-model="conponentName"
      checked="checked"
      type="radio"
      name="tab-common"
      value="ChiTietHoSo"
    >
    <input
      id="tab2"
      type="radio"
      name="tab-common"
    >
    <input
      id="tab3"
      v-model="conponentName"
      type="radio"
      name="tab-common"
      value="TienTrinhXuLy"
    >
    <nav>
      <ul>
        <li class="tab1">
          <label for="tab1">THÔNG TIN HỒ SƠ</label>
        </li>
        <li class="tab2">
          <label for="tab2">XỬ LÝ HỒ SƠ</label>
        </li>
        <li class="tab3">
          <label for="tab3">TIẾN TRÌNH XỬ LÝ</label>
        </li>
      </ul>
    </nav>
    <section>
      <div class="tab">
        <component
          :is="conponentName"
          :table-data="getDataTable()"
          @prew-processing="prewProcessing"
          @close-page="closePage"
        >
          <template
            slot="actionsTths"
          >
            <div />
          </template>
        </component>
      </div>
    </section>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
    >
      <component
        :is="componentModal"
        ref="componentName"
        :data-form="dataForm"
      />
    </common-modal>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import ChiTietHoSo from '@/components/ChiTietHoSo.vue'
import TienTrinhXuLy from '@/components/TienTrinhXuLy.vue'
import LuongXuLySchema from '@/components/LuongXuLySchema.vue'
import CommonModal from '@/modules/xu-ly-ho-so/components/modal/CommonModal.vue'
import * as dayjs from 'dayjs'
import { ROUTE_NAME } from '@/modules/xu-ly-ho-so/constants/constants'

export default {
  components: {
    ChiTietHoSo,
    TienTrinhXuLy,
    LuongXuLySchema,
    CommonModal,
  },
  data() {
    return {
      conponentName: 'ChiTietHoSo',
      componentModal: 'LuongXuLySchema',
      size: 'lg',
      title: 'Luồng xử lý hồ sơ',
      data: {
        date: null,
        people: null,
        name: null,
        address: null,
        email: null,
        phone: null,
        cmt: null,
      },
      dataForm: null,
      hoSoId: null,
      detail: null,
      rowhs: [],
      rowgt: [],
      rowgtk: [],
    }
  },
  created() {
    // this.hoSoId = this.$route.query.id
    // this.getDataMauHoSo()
  },
  methods: {
    formatDate(date) {
      return date ? dayjs(date).format('HH:mm - DD/MM/YYYY') : null
    },
    differenceInMinutes(start, end) {
      const totalMinutes = value => {
        const match = (/(\d{1,2}):(\d{1,2})/g).exec(value)
        return (Number(match[1]) * 60) + Number(match[2])
      }
      return totalMinutes(end) - totalMinutes(start)
    },
    convertMinsToHrsMins(minutes) {
      let h = Math.floor(minutes / 60)
      let m = minutes % 60
      h = h < 10 ? `0${h}` : h
      m = m < 10 ? `0${m}` : m
      return `${h}:${m}`
    },
    getDataMauHoSo() {
      this.$axios.get(`${END_POINTS.TIEP_NHAN_HO_SO_TRUC_TIEP.CHI_TIET}?id=${this.hoSoId}`).then(res => {
        if (res.data?.succeeded) {
          this.detail = res.data.data
          this.data = {
            ngayNop: this.formatDate(this.detail.ngayNop),
            nguoiNop_HoVaTen: this.detail.nguoiNop_HoVaTen,
            chuHoSo_HoVaTen: this.detail.chuHoSo_HoVaTen,
            thoiGianDenHanTraKQ: this.convertMinsToHrsMins(this.differenceInMinutes(this.detail.gioHenTraTu, this.detail.gioHenTraDen)),
            diaChiFull: this.detail.diaChiFull,
            ngayTiepNhan: this.formatDate(this.detail.ngayNop),
            chuHoSo_DiaChiEmail: this.detail.chuHoSo_DiaChiEmail,
            chuHoSo_SoDienThoai: this.detail.chuHoSo_SoDienThoai,
            chuHoSo_CMND_HoChieu: this.detail.chuHoSo_CMND_HoChieu,
          }
          this.rowhs = this.detail.hoSoGiayToModels
          this.rowgt = this.detail.hoSoGiayToKhacModels
          this.rowgtk = this.detail.hoSo_GiayToBoSungModels
        }
      })
    },
    getDataTable() {
      if (this.conponentName === 'ChiTietHoSo') {
        return [
          {
            id: 1,
            name: 'Tờ khái theo mẫu quy định',
            main: 1,
            copy: 0,
            photo: 0,
            file: [],
          },
          {
            id: 2,
            name: 'Văn bản ủy quyền cho cá nhân thực hiện thủ tục liên quan đến đăng ký doanh nghiệp. Văn bản ủy quyền này không bắt buộc phải công chứng. chứng nhận',
            main: 1,
            copy: 0,
            photo: 0,
            file: [],
          },
          {
            id: 3,
            name: 'Bản sao giấy tờ pháp lý của cá nhân được ủy quyền',
            main: 0,
            copy: 1,
            photo: 0,
            file: [],
          },
        ]
      }
      if (this.conponentName === 'TienTrinhXuLy') {
        return [
          {
            role: 'Bộ phận một cửa',
            job: 'Tiếp nhận và phân loại',
          },
          {
            role: 'Văn phòng cục PTDN',
            job: 'Tiếp nhận',
          },
          {
            role: 'Bộ phận một cửa',
            job: 'Tiếp nhận và phân loại',
          },
          {
            role: 'Lãnh đạo cục PTDN',
            job: 'Lãnh đạo vụ phân công',
          },
          {
            role: 'Lãnh đạo phòng THCS',
            job: 'Lãnh đạo vụ phân công',
          },
          {
            role: 'Chuyên viên xử lý',
            job: 'Thẩm định hồ sơ',
          },
          {
            role: 'Lãnh đạo phòng THCS',
            job: 'Xem xét hồ sơ',
          },
          {
            role: 'Lãnh đạo cục PTDN',
            job: 'Xemn xét hồ sơ',
          },
          {
            role: 'Lãnh đạo Bộ',
            job: 'Phê duyệt giấy phép',
          },
          {
            role: 'Xác nhận đóng dấu',
            job: 'Văn phòng Bộ',
          },
          {
            role: 'Bộ phận một cửa',
            job: 'Trả kết quả',
          },
        ]
      }
      return []
    },
    prewProcessing() {
    },
    closePage() {
      this.$router.push({ path: ROUTE_NAME.BAN_GIAO_HS.DS })
    },
    handleOk() {

    },
  },
}
</script>
<style scoped lang="scss">
.tab-common > input,
.tab-common section > div {
  display: none;
}

#tab1:checked ~ section .tab,
#tab2:checked ~ section .tab,
#tab3:checked ~ section .tab {
  display: block;
}

$activeColor: #005DB4;
$unactiveColor: #eeeeee;
$unactiveHoverColor: #dddddd;

.tab-common {
  width: 100%;
  margin: 0 auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      label {
        float: left;
        padding: 0.5rem 1.5rem;
        border: 1px solid #ddd;
        border-bottom: 0;
        background: #ffffff;
        color: #444;
        margin-bottom: 0 !important;
        &:hover {
          background: $unactiveHoverColor;
          cursor: pointer;
        }
        &:active {
          background: $activeColor;
        }
      }
      &:not(:last-child) label {
         border-right-width: 0;
      }
    }
  }
  section {
    clear: both;
    div {
      width: 100%;
      background: #fff;
      line-height: 1.5em;
      letter-spacing: 0.3px;
      color: #444;
      h2 {
        margin: 0;
        letter-spacing: 1px;
        color: #34495e;
      }
    }
  }
}

#tab1:checked ~ nav .tab1,
#tab2:checked ~ nav .tab2,
#tab3:checked ~ nav .tab3 {
  label {
    background: $activeColor;
    color: #fff;
    position: relative;
    &:after {
      content: '';
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: $activeColor;
      left: 0;
      bottom: -1px;
    }
  }
}
</style>
